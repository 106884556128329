import React, { useContext } from "react";

import { AppContext } from "../../App";
import { subtitle, languageSetting } from "../../translations";

import "./Header.scss";

const Header = function Header() {
    const { language, setLanguage } = useContext(AppContext);

    function handleLanguageButtonClick() {
        if (language === "en") {
            setLanguage("fr");
        } else {
            setLanguage("en");
        }
    }

    return (
        <div className="header">
            <div className="header__banner">
                <div className="header__banner__buttons">
                    <div className="header__banner__buttons__links">
                        <a href="mailto:info@clairebeauregard.com"><i className="material-icons">mail_outline</i></a>
                        <a href="https://www.instagram.com/cbeaureg.art/"><span className="fa fa-instagram"></span></a>
                    </div>
                    <div className="header__banner__buttons__language" onClick={handleLanguageButtonClick}>
                        {languageSetting[language]}
                    </div>
                </div>
                <div className="header__banner__title">Claire Beauregard</div>
                <div className="header__banner__subtitle">
                    <p>{subtitle[language]}</p>
                </div>
            </div>
        </div>
    );
}

export default Header;