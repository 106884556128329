import React, { useState } from "react";
import Header from "./components/Header";
import Modal from "./components/Modal/Modal";
import Gallery from "./pages/Gallery";
import { Outlet } from "react-router-dom";

import "./App.scss";

const AppContext = React.createContext();

const App = function App() {

    const [language, setLanguage] = useState("fr");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(1);

    return (
        <AppContext.Provider value={{ language, setLanguage, setIsModalOpen, setSelectedImage }} >
            <Modal isModalOpen={isModalOpen}>
                <Gallery selectedImage={selectedImage} />
            </Modal>
            <div className="app">
                <Header />
                <div className={`app__content ${isModalOpen ? "app__content--isModal" : ""}`}>
                    <Outlet />
                </div>
            </div>
        </AppContext.Provider>
    );
};

export default App;
export { AppContext };