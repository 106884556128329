import React, { useCallback, useContext, useEffect, useState } from "react";

import { AppContext } from "../../App";
import { gallery } from "../../translations";

import { images } from "../../utilities/imageUtility";

import "./GalleryClip.scss";

const GalleryClip = function GalleryClip({ onClick }) {
    const { language, setSelectedImage } = useContext(AppContext);

    const refs = images.reduce((acc, value) => {
        acc[value.f_id] = React.createRef();
        return acc;
    }, {});

    const [currentImagePosition, setCurrentImagePosition] = useState(0);
    const [listImageInView, setListImageInView] = useState(images[0].f_id);

    const changeImage = useCallback(function changeImage() {
        const selector1 = document.getElementById("selector1");
        const selector2 = document.getElementById("selector2");

        const point = currentImagePosition < images.length / 2 ? selector1.getBoundingClientRect().top : selector2.getBoundingClientRect().bottom;

        let imageAbove, imageBelow, imageAboveTop, imageAboveBottom, imageBelowTop, imageBelowBottom;

        if (currentImagePosition !== 0) {
            imageAbove = document.getElementById(`img-${listImageInView - 1}`).getBoundingClientRect();
            imageAboveBottom = imageAbove.bottom;
            imageAboveTop = imageAbove.top;
        }

        if (currentImagePosition !== images.length - 1) {
            imageBelow = document.getElementById(`img-${listImageInView + 1}`).getBoundingClientRect();
            imageBelowBottom = imageBelow.bottom;
            imageBelowTop = imageBelow.top;
        }


        if (point >= imageBelowTop && point <= imageBelowBottom) {
            setListImageInView(images[currentImagePosition + 1].f_id);
            setCurrentImagePosition(prev => prev + 1)
        }

        if (point >= imageAboveTop && point <= imageAboveBottom) {
            setListImageInView(images[currentImagePosition - 1].f_id);
            setCurrentImagePosition(prev => prev - 1)
        }
    }, [setCurrentImagePosition, setListImageInView, listImageInView, currentImagePosition]);

    const handleClick = useCallback(function handleClick() {
        setSelectedImage(currentImagePosition);
        onClick();
    }, [onClick, currentImagePosition]);

    function remoteScroll(event) {
        if (document.body.clientWidth < 1200) {
            return;
        }

        const scrollChange = event.deltaY;
        console.log(currentImagePosition);
        if (currentImagePosition === images.length - 1 && scrollChange > 0) {
            return;
        } else if (currentImagePosition === 0 && scrollChange < 0) {
            return;
        }

        event.preventDefault();
        const list = document.getElementById("image-scroll");
        list.scrollBy({ top: scrollChange, behavior: "auto" });
    }

    useEffect(() => {
        const list = document.getElementById("gallery-clip");
        list.addEventListener("wheel", remoteScroll, { passive: false });

        return () => {
            list.removeEventListener("wheel", remoteScroll);
        }
    });

    return (
        <div className="gallery-clip" id="gallery-clip" onClick={handleClick}>
            <div className="background-wrapper">
                <img className="gallery-clip__background-img" src={images[currentImagePosition].file} />
            </div>
            <div className="gallery-clip__text">
                {gallery[language]}
            </div>
            <div id="selector1" />
            <div id="selector2" />
            <div className="gallery-clip__image-scroll" id="image-scroll" onScroll={changeImage}>
                <ul className="gallery-clip__image-scroll__list" id="image-list">
                    {
                        images.map((image) => {
                            return (
                                <li ref={refs[image.f_id]} key={image.f_id} id={`img-${image.f_id}`} className="gallery-clip__image-scroll__list__list-item">
                                    <img src={image.file} />
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </div >
    );
}

export default GalleryClip;
