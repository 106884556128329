import React from "react";

import "./Section.scss";

const Section = function Section({ children, style = "plain" }) {

    return (
        // style types = plain, color, neutral
        <div className={`page-section page-section--${style}`}>
            <div className="page-section__content">
                {children}
            </div>
        </div>
    );
}

export default Section;