import React from "react";
import PhotoGallery from "../../components/PhotoGallery/PhotoGallery";

import { images } from "../../utilities/imageUtility";

import { imageData } from "../../utilities/descriptions";

import "./Gallery.scss";



const Gallery = function Gallery({ selectedImage }) {

    return (
        <div className="gallery-page" >
            <div className="gallery-page__content">
                <PhotoGallery images={images} data={imageData} selectedImage={selectedImage} />
            </div>
        </div >
    );
}

export default Gallery;