export const languageSetting = {
    en: "FR",
    fr: "EN"
}
export const subtitle = {
    en: "Fine Artist",
    fr: "Artiste peintre"
};

export const gallery = {
    en: "Gallery",
    fr: "Galerie"
}
export const mission = {
    en: "My mission is to explore nature on all scales and translate it onto canvas.",
    fr: "Ma motivation est d'explorer la nature à petite et grande échelle pour la transposer sur la toile."
};

export const wish = {
    en: "I always wanted to be an artist.",
    fr: "J'ai toujours voulu être artiste."
}

export const about = {
    en: `My interest in painting started as a little girl, my mom taught me how to draw and paint like her.
    I was encouraged throughout the years by my teachers and classmates to pursue a career in visual arts despite my growing interest in biology.
    I graduated from Animation in Montreal having gained valuable illustration and drawing skills.
    I later decided to follow my own path as a "self-taught" painter. So life goes on, and I'm hoping to one day earn a living off of my passion!`,
    fr: `Mon intérêt pour la peinture est né dans l'enfance des apprentissages faits auprès de ma mère, elle-même peintre et dessinatrice à ses heures.
    Autant à l'école qu'à la maison, j'ai été encouragée toute ma jeunesse à choisir la voie des arts malgré un intérêt grandissant pour la biologie et l'écologie.
    J'ai obtenu d'abord un diplôme en dessin animé au cégep du Vieux Montréal qui m'a fourni, entre autres, une bonne formation en dessin et en illustration.
    Après un crochet en architecture de paysage, j'ai plus tard décidé de suivre mon propre chemin en temps qu'artiste peintre « autodidacte ».
    Ainsi va la vie, et j'espère être un jour capable de vivre de mes passions!`
};