import backforest from "../images/paintings/backforest.jpg";
import bouleaux from "../images/paintings/bouleaux.jpg";
import brown_moth from "../images/paintings/brown_moth.jpg";
import butterfly_damier from "../images/paintings/butterfly_damier.jpg";
import cabourons from "../images/paintings/cabourons.jpg";
import chistmas_village from "../images/paintings/christmas_village.jpg";
import dream_city from "../images/paintings/dream_city.jpg";
import dream_house from "../images/paintings/dream_house.jpg";
import elephants from "../images/paintings/elephants.jpg";
import etude_hiver from "../images/paintings/etude_hiver.jpg";
import firefly_twilight from "../images/paintings/firefly_twilight.jpg";
import fishing_cove from "../images/paintings/fishing_cove.jpg";
import forest_dream from "../images/paintings/forest_dream.jpg";
import golden_candle from "../images/paintings/golden_candle.jpg";
import jardin_botanique from "../images/paintings/jardin_botanique.jpg";
import ladybug from "../images/paintings/ladybug.jpg";
import mauricie from "../images/paintings/mauricie.jpg";
import misty_beach from "../images/paintings/misty_beach.jpg";
import misty_forest from "../images/paintings/misty_forest.jpg";
import mou_rusti from "../images/paintings/mou_rusti.jpg";
import my_linden from "../images/paintings/my_linden.jpg";
import nuages_etude from "../images/paintings/nuages_etude.jpg";
import ombelles from "../images/paintings/ombelles.jpg";
import orford from "../images/paintings/orford.jpg";
import pays_monochrome from "../images/paintings/pays_monochrome.jpg";
import poplar_field from "../images/paintings/poplar_field.jpg";
import river_bank from "../images/paintings/river_bank.jpg";
import roselin from "../images/paintings/roselin.jpg";
import salade_petales from "../images/paintings/salade_petales.jpg"
import seed_pods from "../images/paintings/seed_pods.jpg";
import snowpine_forest from "../images/paintings/snowpine_forest.jpg";
import soleil_nuages from "../images/paintings/soleil_nuages.jpg";
import still_here from "../images/paintings/still_here.jpg";
import triptych_1 from "../images/paintings/triptych_1.jpg";
import triptych_2 from "../images/paintings/triptych_2.jpg";
import tulip_vase from "../images/paintings/tulip_vase.jpg";
import tulipes from "../images/paintings/tulipes.jpg";
import vache_highland from "../images/paintings/vache_highland.jpg";

export const images = [
    {
        file: backforest,
        f_id: 0
    },
    {
        file: bouleaux,
        f_id: 1
    },
    {
        file: pays_monochrome,
        f_id: 2
    },
    {
        file: brown_moth,
        f_id: 3
    },
    {
        file: elephants,
        f_id: 4
    },
    {
        file: butterfly_damier,
        f_id: 5
    },
    {
        file: etude_hiver,
        f_id: 6
    },
    {
        file: jardin_botanique,
        f_id: 7
    },
    {
        file: mauricie,
        f_id: 8
    },
    {
        file: misty_beach,
        f_id: 9
    },
    {
        file: misty_forest,
        f_id: 10
    },
    {
        file: mou_rusti,
        f_id: 11
    },
    {
        file: my_linden,
        f_id: 12
    },
    {
        file: nuages_etude,
        f_id: 13
    },
    {
        file: ombelles,
        f_id: 14
    },
    {
        file: roselin,
        f_id: 15
    },
    {
        file: salade_petales,
        f_id: 16
    },
    {
        file: seed_pods,
        f_id: 17
    },
    {
        file: soleil_nuages,
        f_id: 18
    },
    {
        file: still_here,
        f_id: 19
    },
    {
        file: triptych_1,
        f_id: 20
    },
    {
        file: triptych_2,
        f_id: 21
    },
    {
        file: tulipes,
        f_id: 22
    },
    {
        file: vache_highland,
        f_id: 23
    },
    {
        file: cabourons,
        f_id: 24
    },
    {
        file: chistmas_village,
        f_id: 25
    },
    {
        file: dream_city,
        f_id: 26
    },
    {
        file: dream_house,
        f_id: 27
    },
    {
        file: firefly_twilight,
        f_id: 28
    },
    {
        file: forest_dream,
        f_id: 29
    },
    {
        file: orford,
        f_id: 30
    },
    {
        file: poplar_field,
        f_id: 31
    },
    {
        file: river_bank,
        f_id: 32
    },
    {
        file: snowpine_forest,
        f_id: 33
    },
    {
        file: fishing_cove,
        f_id: 34
    },
    {
        file: ladybug,
        f_id: 35
    },
    {
        file: tulip_vase,
        f_id: 36
    },
    {
        file: golden_candle,
        f_id: 37
    }
];