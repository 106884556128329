import React, { useCallback, useState } from "react";

import "./PhotoGallery.scss";

const PhotoGallery = function PhotoGallery({ images, data, selectedImage }) {

    const [currentImagePosition, setCurrentImagePosition] = useState(selectedImage);


    const changeImage = useCallback(function changeImage(event) {
        if (event.target.id === "left") {
            setCurrentImagePosition((prev) => {
                if (prev === 0) {
                    return images.length - 1;
                }
                return prev - 1;
            });
        } else {
            setCurrentImagePosition((prev) => {
                if (prev === images.length - 1) {
                    return 0;
                }
                return prev + 1;
            });
        }
    }, [setCurrentImagePosition]);

    return (
        <div className="photo-gallery">
            <div className="photo-gallery__content">
                <div className="photo-gallery__content__container">
                    <div id="left" className="photo-gallery__content__container__arrow" onClick={changeImage}>{`<`}</div>
                    <div className="photo-gallery__content__container__image">
                        <img className="photo-gallery__content__container__image__img" onContextMenu={(e) => e.preventDefault()} src={images[currentImagePosition].file} />
                        <div className="photo-gallery__content__container__image__description">
                            <div>{data[currentImagePosition].title}</div>
                            <div>{data[currentImagePosition].desc}</div>
                            <div>{data[currentImagePosition].dim}</div>
                        </div>
                    </div>
                    <div id="right" className="photo-gallery__content__container__arrow" onClick={changeImage}>{`>`}</div>
                </div>
            </div>
        </div>
    );
}

export default PhotoGallery;
