import React, { useContext } from "react";

import GalleryClip from "../../components/GalleryClip/GalleryClip";
import Section from "../../components/Section";
import { AppContext } from "../../App";
import { mission, wish, about } from "../../translations";

import "./Home.scss";

import Claire from "../../images/claire-painting.jpeg";

const Home = function Home() {

    const { setIsModalOpen, language } = useContext(AppContext);

    return (
        <div className="home-page">
            <Section>
                <GalleryClip onClick={(imagePosition) => setIsModalOpen(true)} />
            </Section>
            <Section style="color">
                <div className="home-page__meet-artist">
                    <div className="home-page__meet-artist__img">
                        <img src={Claire} />
                    </div>
                    <div className="home-page__meet-artist__text">
                        <div className="home-page__meet-artist__text__title">
                            {mission[language]}
                        </div>
                        <div className="home-page__meet-artist__text__quote">
                            {wish[language]}
                        </div>
                        <div className="home-page__meet-artist__text__body">
                            {about[language]}
                        </div>
                    </div>
                </div>
            </Section>
            <Section>
                <div className="home-page__footer">
                    <p>Claire Beauregard</p>
                    <div className="home-page__footer__links">
                        <a href="mailto:info@clairebeauregard.com"><i className="material-icons">mail_outline</i></a>
                        <a href="https://www.instagram.com/cbeaureg.art/"><span className="fa fa-instagram"></span></a>
                    </div>
                    <p>&copy;2023</p>
                </div>
            </Section>
        </div>
    );
}

export default Home;