export const imageData = [
    {
        title: "The Backforest (2022)",
        desc: "Acrylique sur toile",
        dim: '16" x 8"',
        f_id: 0
    },
    {
        title: "Bouleaux (2022)",
        desc: "Acrylique sur toile",
        dim: '10" x 8"',
        f_id: 1
    },
    {
        title: "Paysage monochrome (2021)",
        desc: "Acrylique sur toile",
        dim: '10" x 8"',
        f_id: 2
    },
    {
        title: "Triphosa haesitata (2023)",
        desc: "Acrylique sur toile",
        dim: '12" x 9"',
        f_id: 3
    },
    {
        title: "Éléphants (2021) - VENDU",
        desc: "Graphite sur papier",
        dim: '2 x (9" x 12")',
        f_id: 4
    },
    {
        title: "Chladyne nycteis, face ventrale (2023)",
        desc: "Acrylique sur toile",
        dim: '12" x 9"',
        f_id: 5
    },
    {
        title: "Étude, hiver (2022)",
        desc: "Acrylique sur toile",
        dim: '7" x 5"',
        f_id: 6
    },
    {
        title: "Au jardin botanique (2022) - VENDU",
        desc: "Acrylique sur toile",
        dim: '7" x 5"',
        f_id: 7
    },
    {
        title: "La Mauricie (2022)",
        desc: "Acrylique sur toile",
        dim: '20" x 10"',
        f_id: 8
    },
    {
        title: "Misty Beach (2022)",
        desc: "Acrylique sur toile",
        dim: '12" x 9"',
        f_id: 9
    },
    {
        title: "Misty Forest (2022)",
        desc: "Acrylique sur toile",
        dim: '7" x 5"',
        f_id: 10
    },
    {
        title: "Peluches (2021) - VENDU",
        desc: "Acrylique sur toile",
        dim: '5" x 3"',
        f_id: 11
    },
    {
        title: "So Long My Linden (2018)",
        desc: "Carte à gratter",
        dim: '3" x 5"',
        f_id: 12
    },
    {
        title: "Étude de nuages (2022)",
        desc: "Acrylique sur toile",
        dim: '9" x 12"',
        f_id: 13
    },
    {
        title: "Les ombelles (2021)",
        desc: "Acrylique sur toile",
        dim: '14" x 11"',
        f_id: 14
    },
    {
        title: "Roselin (2022) - VENDU",
        desc: "Acrylique sur toile",
        dim: '10" x 8"',
        f_id: 15
    },
    {
        title: "Salade de pétales (2022)",
        desc: "Acrylique sur toile",
        dim: '10" x 8"',
        f_id: 16
    },

    {
        title: "Seed Pods (2022) - VENDU",
        desc: "Technique mixte sur papier",
        dim: '2 x (3" x 5")',
        f_id: 17
    },
    {
        title: "Alternance soleil nuages (2022) - VENDU",
        desc: "Acrylique sur toile",
        dim: '48" x 36"',
        f_id: 18
    },
    {
        title: "I'm Still Here (2021) - VENDU",
        desc: "Acrylique sur toile",
        dim: '11" x 14"',
        f_id: 19
    },
    {
        title: "Village de Provence 1 (2021) - VENDU",
        desc: "Acrylique et huile sur toile",
        dim: '10" x 20"',
        f_id: 20
    },
    {
        title: "Village de Provence 2 (2021) - VENDU",
        desc: "Acrylique et huile sur toile",
        dim: '10" x 20"',
        f_id: 21
    },
    {
        title: "Tulipes (2022) - VENDU",
        desc: "Acrylique sur toile",
        dim: '7" x 5"',
        f_id: 22
    },
    {
        title: "Vache Highland (2018)",
        desc: "Impression sur papier cartonné",
        dim: '8" x 8"',
        f_id: 23
    },
    {
        title: "Le cabouron (2023)",
        desc: "Acrylique sur toile",
        dim: '24" x 36"',
        f_id: 24
    },
    {
        title: "Christmas Village (2023)",
        desc: "Acrylique sur toile",
        dim: '10" x 8"',
        f_id: 25
    },
    {
        title: "Ville (2023)",
        desc: "Acrylique sur toile",
        dim: '3" x 2"',
        f_id: 26
    },

    {
        title: "House in a Green Neighborhood (2023)",
        desc: "Acrylique sur toile non montée",
        dim: '4" x 4"',
        f_id: 27
    },
    {
        title: "Forêt enchantée (2023)",
        desc: "Acrylique sur toile",
        dim: '7" x 5"',
        f_id: 28
    },
    {
        title: "Sans titre - Forêt (2023)",
        desc: "Acrylique sur toile",
        dim: '14" x 16"',
        f_id: 29
    },
    {
        title: "Orford, lac d'Argent(2023)",
        desc: "Acrylique sur toile",
        dim: '40" x 16"',
        f_id: 30
    },
    {
        title: "Le peuplier (2023)",
        desc: "Acrylique sur toile",
        dim: '32" x 20"',
        f_id: 31
    },
    {
        title: "Landscape (2023) ",
        desc: "Acrylique sur toile",
        dim: '7" x 5"',
        f_id: 32
    },
    {
        title: "Sentier de neige (2022) - VENDU",
        desc: "Acrylique sur toile",
        dim: '20" x 28"',
        f_id: 33
    },
    {
        title: "Village de pêche (2023)",
        desc: "Acrylique sur toile",
        dim: '12" x 16"',
        f_id: 34
    },
    {
        title: "Coccinelle imaginaire (2023)",
        desc: "Acrylique sur toile",
        dim: '12" x 9"',
        f_id: 35
    },
    {
        title: "Bouquet de tulipes (2023)",
        desc: "Acrylique sur toile",
        dim: '9" x 12"',
        f_id: 36
    },
    {
        title: "La bougie (2024)",
        desc: "Acrylique sur toile",
        dim: '7" x 5"',
        f_id: 37
    },
];