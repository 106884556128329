import React, { useContext } from "react";
import { AppContext } from "../../App";
import "./Modal.scss";

const Modal = function Modal({ isModalOpen, children }) {

    const { setIsModalOpen } = useContext(AppContext);

    return (
        isModalOpen &&
        <div className="modal">
            <div onClick={() => setIsModalOpen(false)} className="modal__close">X</div>
            {children}
        </div>
    )
}

export default Modal;